$video-ratio-w: 80%; //80:100 = x : 56.25 ( x = $video-ratio-h) (56.25 = 16:9 ratio)
$video-ratio-h: 45%;

$spinner-bt-color: rgba(white, .2);

.video-holder {

    position: relative;
    z-index: 1;
    height: 70vh;
    width: 100%;
    overflow: hidden;

    .video-start, .video-ended, .video-go-full {
        @include size(70px);
        @include position(absolute, 50% null null 50%);
        margin-top: -35px;
        margin-left: -35px;
        text-align: center;
        border-radius: 80px;
        z-index: 40;
        background-color: $action-color;
        a {
            display: block;
            @include position(absolute, 0);
            background-image: url(/images/icons/icon-play-gallery.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            filter: map-get($filters, white);
            @include hide-text;
        }
    }

    .video-ended {
        display: none;
    }

    .video-poster {
        @include position(absolute, 0);
        z-index: 20;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    div.video-js {
        z-index: 10;
        width: 100%;
        height: 100% ;
        overflow: hidden;

        video {
            
            z-index: 10;
            position: absolute;
            top:50%;
            left:50%;
            height:auto;
            width:auto;
            min-width:100%;
            min-height:100%;
            transform:translate(-50%, -50%);
            object-fit:cover;
            outline: none ;

        }

        .vjs-control-bar {
            z-index: 20;
        }

        .vjs-poster,
        .vjs-volume-control,
        .vjs-big-play-button,
        .vjs-fullscreen-control {
            display: none !important;
        }

        .vjs-control {
            width: 4em !important;
            outline: none;
        }

        .vjs-loading-spinner {
            border-color: rgba(black, .5);
            z-index: 30;
        }

        * {
            box-shadow: none !important;
            text-shadow: none !important;
        }

    }
}


#overlay {
    #video-ratio {
        @include position(absolute, 50% null null 50%);
        padding-bottom: $video-ratio-h;
        margin-top: -($video-ratio-h/2);
        width: $video-ratio-w;
        margin-left: -($video-ratio-w/2);

        .video-holder {
            @include size(100% auto);
            @include position(absolute, 0);
            div.video-js {
                position: absolute;

                video {
                    min-width: auto;
                    min-height: auto;
                    width: 100%;
                }

            }
        }
    }
}


.video-play {
    text-decoration: underline;
    &.pause {
        text-decoration: none;
        opacity: .5
    }
}
.video-mute {
    text-decoration: underline;
    &.mute {
        text-decoration: none;
        opacity: .5
    }
}

.video-close {
  @extend .flex-close;
}

@include keyframes(vjs-spinner-fade) {
  0% {
    border-top-color: $spinner-bt-color; }
  20% {
    border-top-color: $spinner-bt-color; }
  35% {
    border-top-color: white; }
  60% {
    border-top-color: $spinner-bt-color; }
  100% {
    border-top-color: $spinner-bt-color; } 
}