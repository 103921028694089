.img-holder {
    position: relative;
    z-index: 1;
    img {
        position: relative;
        z-index: 1;
    }
    .multimedia {
        @include position(absolute, null 20px 20px null);
        z-index: 2;
        a {
            @include size(70px);
            float:left;
            background-color: $action-color;
            position: relative;
			z-index: 1;
			border-radius: 100%;
            &:after {
                content: "";
                @include position(absolute, 0);
                z-index: 1;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 30px auto;
                filter: map-get($filters, white);
            }

            @include media($small) {
                @include size(50px);
                &:after {
                    background-size: 25px auto;
                }
            }

            &.open-gallery:after {
                background-image: url(/images/icons/icon-open-gallery.svg)
            }
            &.open-video:after {
                background-image: url(/images/icons/icon-open-video.svg)
            }
            & + a {
                margin-left: 10px;
            }
        }
    }
}

.icon {
    @extend .tagline;
    position: relative;
    z-index: 1;
    padding-left: 60px;
    &:after {
        content: "";
        @include position(absolute, 50% null null 0);
        background-position: left center;
        background-repeat: no-repeat;
        filter: map-get($filters, dark);
    }
    &.car:after {
        @include size(46px 31px);
        margin-top: -15.5px;
        background-image: url(/images/icons/icon-arrival-car.svg)
    }
    &.plane:after {
        @include size(43px 43px);
        margin-top: -21.5px;
        background-image: url(/images/icons/icon-arrival-plane.svg)
    }
    &.train:after {
        @include size(40px 52px);
        margin-top: -26px;
        background-image: url(/images/icons/icon-arrival-train.svg)
    }

    &.nature:after {
        @include size(46px 32px);
        margin-top: -16px;
        background-image: url(/images/icons/icon-why-nature.svg)
    }
    &.building:after {
        @include size(42px 46px);
        margin-top: -23px;
        background-image: url(/images/icons/icon-why-historical-building.svg)
    }
    &.view:after {
        @include size(37px 37px);
        margin-top: -18.5px;
        background-image: url(/images/icons/icon-why-view-to-bodensee.svg)
    }
}

.icons-list {
    @include set-property(
        (margin-top,),
        ($margins-standard,)
    );
    > div {
        
        border-top: $base-border;
        padding: 2em 0;
        &:last-child {
            border-bottom: $base-border;
        }

        @include media($large) {
            @include span-columns(12);
            > div {
                @include span-columns(6 of 12);
            }
        }
        @include media($medium-down) {
            padding: 3em 0;
            p.icon {
                margin-bottom: 3em;
            }
        }
        @include media($medium) {
            @include span-columns(10);
            @include shift(1);
        }

    }
    .box.background & > div {
        border-top-color: white;
        &:last-child {
            border-bottom-color: white;
        }
    }
}

.list-item {
    @include media($small-up) {
        @include span-columns(6 of 12);
        @include omega(2n);
        &:nth-child(n+3) {
            @include set-property(
                (margin-top,),
                ($margins-reduced,)
            );
        }
    }
    @include media($small) {
        &:nth-child(n+2) {
            margin-top: nth($gutter-ver-in-vw, 3);
        }
    }
    text-align: center;
    > a {
        display: block;
    }
    img {
        display: block;
        width: 100%;
    }
}

.filters {
    select {
        @include media($small-up) {
            display: inline-block;
            width: auto;
            & + select {
                margin-left: 1rem;
            }
        }
        @include media($small) {
            & + select {
                margin-top: 1rem;
            }
        }
    }
    @include set-property(
        (margin-bottom,),
        ($margins-reduced,)
    );
}

.clickable-list {
    @include clearfix;
    > a {
        @include span-columns(12);
        padding: 2rem 0;
        position: relative;
        z-index: 1;
        text-decoration: none;
        &:after {
            content: "";
            background-image: url(/images/icons/icon-right-arrow-small.svg);
            background-repeat: no-repeat;
            z-index: 1;
            @include size(13px 11px);
            filter: map-get($filters, dark);
            background-position: center;
            @include transition(right, $base-duration);

            @include media($small-up) {
                @include position(absolute, 2rem 10px null null);
                margin-top: 5.5px;
            }

            @include media($small) {
                @include position(absolute, null null 16px 0);
            }

          }
        &:first-child {
            border-top: $base-border;
        }
        border-bottom: $base-border;
        > div {

            @include media($small-up) {
                &:first-child {
                    @include span-columns(3 of 12);
                }
                &:last-child {
                    @include span-columns(8 of 12);
                }
            }

            &:first-child {
                p {
                    @extend .tagline;
                }
            }

        }
        &:hover:after {
            right: 20px;
        }
    }
}


.clickable-list-2 {
	margin-bottom: 2em;
	> div {
		@include clearfix;
		&:first-child {
            border-top: 1px solid $base-font-color;
        }
		border-bottom: 1px solid $base-font-color;
		padding: 2em 0;
		> div {
			&:first-child {
				@include span-columns(8);
				h3 {
					color: $base-font-color;
				}
			}
			&:last-child {
				@include span-columns(4);
				text-align: right;
			}
		}
	}
	& + p.link {
		text-align: center;
	}
}



table.meeting-table {
    th {
        @extend .tagline;
        vertical-align: middle;
        margin-bottom: 0;
        img {
            filter: map-get($filters, dark );
            vertical-align: middle;
            display: inline-block;
        }
    }
    td, th {
        padding: 1.8rem 1rem;
        white-space: nowrap;
        &:not(:first-child):not(:last-child) {
            text-align: center;
            white-space: nowrap;
        }
    }
    td {
        vertical-align: top;
        &:first-child {
            text-align: left;
            padding-left: 0;
        }
        &:last-child {
            text-align: right;
            padding-right: 0;
            a {
                display: inline-block;
                background-image: url(/images/icons/icon-right-arrow-small.svg);
                background-repeat: no-repeat;
                background-position: top right;
                position: relative;
                right: 10px;
                z-index: 1;
                @include size(13px 11px);
                filter: map-get($filters, accent);
                @include transition(right, $base-duration);
            }
        }
    }
    tr:not(:first-child) {
        cursor: pointer;
        &:hover td:last-child a {
            right: 20px;
        }
    }
}

.contact {
    text-align: center;
    img {
        display: inline-block;
        width: 55%;
    }
}

ul.list-checked {
	li {
		text-align: center;
		& + li {
			margin-top: .5rem;
		}
		p {
			position: relative;
			padding-left: 35px;
			display: inline-block;
			&:after {
				content: "";
				@include size(20px);
				@include position(absolute, 0 null null 0);
				background-image: url(/images/icons/icon-check.svg);
				background-position: center;
				background-repeat: no-repeat;
				filter: map-get($filters, accent);
			}
		}
	}
}

.double-button {
	display: flex;
	justify-content: center;
	&:not(:last-child) {
		margin-bottom: 2em;
	}

	@include media($medium-down) {
		display: block;
		a {
			display: block !important;
		}
	}

	li {
		a {
			@extend .button;
			margin-top: 0;
		}
		&:first-child a {
			background-color: $orange;
			color: white;
		}
		&:last-child a {
			background-color: white;
			border: 1px solid $orange;
			color: $orange;
		}
	}
}


ul.logos {
	position: relative;
	text-align: center;
	overflow: hidden;
	&:not(:first-child){
		margin-top: 3.5em;
	}
	&:not(:last-child){
		margin-bottom: 3.5em;
	}
	margin: 0 -1em;
	li {
		margin: .5em 1em;
		display: inline-block;
		vertical-align: middle;
		a {
			display: inline-block;
		}
		img {
			display: inline-block;
		}
	}
}

// https://www.wrike.com/open.htm?id=578394637
.big {
	font-size: 1.125em;
	font-family: "Quattrocento";
}
// https://www.wrike.com/open.htm?id=578394805
.footer a, .main-nav .info a {
	text-decoration: none;
}