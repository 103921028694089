.box4 {
    > div {
        .slideshow-holder.hero {
            @include media($large) {
                height: calc(100vh - #{nth($top-nav-height, 1)});
            }
            @include media($medium) {
                height: calc(100vh - 55px - #{nth($top-nav-height, 2)});
            }
            @include media($small) {
                height: 100vw;
            }

            .slideshow .flexslider .slides > li .flex-caption {
                background-color: rgba(black, .25);
            }

            .flex-control-nav {
                display: none;
            }
            .flex-direction-nav {
                display: block;
            }

        }
    }
}