.slideshow-holder {
	&.hero {
		position: relative;
		overflow: hidden;
		@include size(100% 100vh);
		background-color: black;

		//custom
		.slideshow .flexslider {
			.slides > li {
				//il tempo di transizione deve essere minore o uguale di slideshowSpeed
				picture {
					@include transition(transform 6s);
					@include transform(scale(1));
				}
				//gradiente per testo (radiale, totale, ...)
				.flex-caption {
					//background-color: rgba(black, .7);

					@include set-property(padding-left padding-right, $paddings-outer $paddings-outer);

					> div > div {
						* {
							color: white;
						}

						p.link a:after {
							background-color: white;
						}
					}
				}
				&.flex-active-slide {
					picture {
						@include transform(scale(1.05));
					}
				}
			}
		}
	}

	&.mono {
		width: 100%;
	}

	//hero, fullscreen e mono
	.slideshow {
		.flexslider {
			.slides {
				> li {
					&[data-class="class-1"] .flex-caption > div > div {
						color: blue;
					}
					&[data-class="class-2"]:after {
						content: "";
						@include position(absolute, 0);
						background-color: rgba(red, 0.5);
						z-index: 3;
					}
				}
			}
		}
	}
}

.overlay-hero {
	.slideshow {
		.flexslider {
			padding: 0;
			.slides > li {
				picture {
					@include position(absolute, 0); //50px
				}

				.flex-caption {
					//gradiente per testo (solo se presente)
					&:after {
						content: "";
						@include position(absolute, null 0 0 0);
						height: 30%;
						z-index: 7; //sopra .slides ma sotto pallini e frecce
						@include background-image(linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%));
					}
					> div > div {
						position: relative;
						z-index: 10;
						vertical-align: bottom;
						//padding: 0;
						line-height: 50px;

						* {
							color: white;
						}
					}
				}

				.flex-share {
					display: none;

					ul {
						text-align: center;
						li {
							display: inline-block;
							a {
								display: block;
								@include size(40px);
								@include hide-text;
								&.facebook {
									background-color: aquamarine;
								}
								&.twitter {
									background-color: chocolate;
								}
								&.pinterest {
									background-color: red;
								}
							}
						}
					}
				}
			}
		}
	}
}

//close (condiviso con video close)
.flex-close {
	@include position(absolute, 0 null null null);
	cursor: pointer;
	z-index: 110;
	background-color: $orange;
	@include size(70px);
	border-radius: 100%;
	top: 30px;
	right: 30px;
	&:after {
		content: "";
		@include position(absolute, 0);
		background-image: url(/images/icons/icon-close.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 25px;
		filter: map-get($filters, white);
	}

	@include media($medium-down) {
		@include size(50px);
		top: 10px;
		right: 10px;
		&:after {
			background-size: 15px;
		}
	}

}
//frecce condivise con tutti i tipi di flexslider (fancybox compreso)
.ext-flex-prev-next {
	@include position(absolute, 50% null null null);
	@include hide-text;
	@include size(50px);
	margin-top: -25px;
	z-index: 99;
	background-repeat: no-repeat;
	filter: map-get($filters, white);
}
.ext-flex-prev {
	background-image: url(/images/icons/icon-left-arrow.svg);
	background-position: center left;

	@include media($large) {
		background-size: 27px 22px;
		left: 60px;
	}
	@include media($medium) {
		background-size: 14px auto;
		left: 30px;
	}
	@include media($small) {
		background-size: 14px auto;
		left: 20px;
	}
}
.ext-flex-next {
	background-image: url(/images/icons/icon-right-arrow.svg);
	background-position: center right;

	@include media($large) {
		background-size: 27px 22px;
		right: 60px;
	}
	@include media($medium) {
		background-size: 18px auto;
		right: 30px;
	}
	@include media($small) {
		background-size: 18px auto;
		right: 20px;
	}
}

.ext-flex-control-nav {
	z-index: 100;
	text-align: center;
	@include position(absolute, 50px 0 null 0);
	line-height: 10px;
	z-index: 1;

	li {
		display: inline-block;
		margin: 0;
		vertical-align: middle;
		width: 30px;
		text-align: center;

		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
		a {
			display: block;
			@include size(6px);
			@include hide-text;
			border-radius: 6px;
			background-color: red;
			cursor: pointer;
			margin: 0 auto;

			&.flex-active {
				background-color: transparent;
				@include size(12px);
				border-radius: 12px;
				position: relative;
				z-index: 1;
				border: 1px solid red;
				&:after {
					content: "";
					@include position(absolute, 2px);
					border-radius: 6px;
					@include size(6px);
					background-color: red;
				}
			}
		}
	}
}
//specifico per carosello, solitamente in negativo
.ext-flex-control-nav-carousel {
	top: auto;
	bottom: 0;
	li {
		a {
			background-color: $font-dark;
			&.flex-active {
				border: 1px solid $font-dark;
				&:after {
					background-color: $font-dark;
				}
			}
		}
	}
}

.carousel {
	.flexslider {
		@include media($medium-down) {
			padding-bottom: 60px; //50 + 10
		}

		.flex-viewport {
			margin: 0;
			@include set-property(margin-left margin-right, $gutter-hor-in-vw $gutter-hor-in-vw, null, negative);
		}
		.slides {
			> li {
				> div {
					white-space: normal;

					padding: 0;

					@include set-property(padding-left padding-right, $gutter-hor-in-vw $gutter-hor-in-vw);

					// > a, > a > img, > img {
					//     margin-bottom: 3rem;
					// }
				}
			}
		}
	}
}

// Carousel center mono
.carousel.center.mono {
	@include media($medium-down) {
		.flexslider {
			width: 50vw;
			margin: 0 auto;
			.flex-direction-nav {
				a {
					width: 23vw;
				}
				.flex-next {
					right: -25vw;
				}
				.flex-prev {
					left: -25vw;
				}
			}
		}
	}

	@include media($large) {
		.flexslider {
			width: 50vw;
			margin: 0 0 0 10vw;
			.flex-direction-nav {
				.flex-next {
					width: 38vw;
					right: -40vw;
				}
				.flex-prev {
					width: 8vw;
					left: -10vw;
				}
			}
		}
	}

	.flexslider {
		.flex-viewport {
			.slides {
				> li {
					> div {
						padding: 0 2vw;
						> div {
							padding: 1rem 0 0;
							text-align: left;
						}
					}
				}
			}
		}
	}
}

// Carousel center multi
.carousel.center.multi {
	@include media($medium-down) {
		.flexslider {
			width: 90vw;
			margin: 0 auto;
			.flex-direction-nav {
				a {
					width: 23vw;
				}
				.flex-next {
					right: -25vw;
				}
				.flex-prev {
					left: -25vw;
				}
			}
		}
	}

	@include media($large) {
		.flexslider {
			width: 40vw;
			margin: 0 0 0 10vw;
			.flex-direction-nav {
				a {
					width: 8.5vw;
				}
				.flex-next {
					right: -50vw;
				}
				.flex-prev {
					left: -10vw;
				}
			}
		}
	}

	.flexslider {
		.flex-viewport {
			.slides {
				> li {
					opacity: 1;
					> div {
						padding: 0 1.5vw;
						> div {
							padding: 1rem 0 0;
							text-align: left;
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
