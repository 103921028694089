//slideshow template
.slideshow {
  @include size(100%);
  overflow: hidden;
  position: relative;
  z-index: 1;

  .flexslider {

    .slides  { //out from -viewport for fade effect
      height: 100%;
      position:relative;
      z-index: 5;
      
      > li {
        position:relative;
        height: 100%;
        display: none;
      }
    }

    .flex-direction-nav {
      .flex-prev, .flex-next {
        @extend .ext-flex-prev-next;
      }
      .flex-prev {
        @extend .ext-flex-prev;
      }
      .flex-next {
        @extend .ext-flex-next;
      }
    }
    .flex-control-nav {
      @extend .ext-flex-control-nav;
    }

    .flex-share,
    .flex-close {
      display: none;
    } 

    &.loading {
      .spinner {
        display: block;
      }
    }
  }

  //fullscreen
  .overlay-hero & {
    @include position(absolute, 0);

    .flex-share,
    .flex-close {
      display: block;
    }
    .flexslider  {
      .slides {
        overflow: hidden;
      }
    }

  }
} 

.slideshow-holder.hero {
  .slideshow {
    .flexslider {
      .slides  {
        > li {
          picture {
            background-size: cover;
          }
          .video-holder {
            height: 100%;
          }
        }
      }
    }
  }
}

//slideshow classico (hero) e fullscreen
.slideshow-holder.hero, .overlay-hero {
  .slideshow {
    .flexslider {
      height: 100%;
      position: relative;
      .slides  {
        > li {

          picture {
            @include position(absolute, 0);
            background-repeat: no-repeat;
            background-position: center;
            z-index: 2;
            img {
              display: none;
            }
          }

          // caption
          .flex-caption {
            @include position(absolute, 0);
            z-index: 5;
            
            > div { 
              display:table;
              @include size( 80% 100% );
              margin: 0 auto;

              > div {
                text-align: center;
                display: table-cell;
                vertical-align: middle;
              }
            }
          }

          //link di sharing
          .flex-share {
            @include position(absolute, 0 0 null 0);
            z-index: 5;
          }


        }
      }
    }
  }
}

.overlay-hero {
  .slideshow {
    .flexslider {
      .slides  {
        > li {
          picture {
            background-size: contain;
          }
        }
      }
    }
  }
}

//slideshow mono
.slideshow-holder.mono {
  .slideshow {
    .flexslider {
      .slides  {
        > li {
          picture {
            display: block;
            width: 100%;
            background-image: none !important;
            img {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
    .flex-slides-nav {
      @include position(absolute, null 0 0 null);
    }
  }
}



// Carosello 
.carousel {

  .flexslider {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    
    .slides {
      width: 100% !important;
      text-align: center;
      //font-size: 0px;
      > li {
        vertical-align: top;
        float: none !important;
        width: 0;
        overflow: hidden;
        > div {
          // * {
          //   font-size: 1rem;
          // }
          > a > img, > img {
            display: block;
            width: 100%;
          }
          > a {
            display: block;
          }
        }
        display: none;
        &[style*="block"] {
          display: inline-block !important; 
        }
      }
    }
    
    .flex-direction-nav {
      .flex-prev, .flex-next {
        @extend .ext-flex-prev-next;
      }
      .flex-prev {
        @extend .ext-flex-prev;
      }
      .flex-next {
        @extend .ext-flex-next;
      }
    }
    .flex-control-nav {
      @extend .ext-flex-control-nav, .ext-flex-control-nav-carousel;
    }

  }

}


// Carousel center
.carousel.center {
  margin: 0;
  overflow: hidden;
  .flexslider {
    overflow: visible;
    .flex-viewport {
      margin: 0 ;
      overflow: visible !important;
      .slides  {
        > li  {
          @include transition(opacity $base-duration);
          opacity: .3;
          > div {
            > div {
              font-size: 1rem;
              opacity: 0;
            }
          }
          &.flex-active-slide {
            opacity: 1;
            > div > div {
              opacity: 1;
            }
          }
        }
      }
    }
    .flex-direction-nav {
      a {
        top: 0;
        bottom: 0;
        height: auto;
        margin: 0;
        outline:0;
      }
    }
  }
}




////////////////////////////////////////////////////////////////////
//di base:
//direction-nav visibile da large e nascosto da medium-down
//control-nav visibile fino a medium e nascosto da medium-up
.flexslider {
  .flex-direction-nav {
    @include media($medium-down) {
      display: none;
    }
    @include media($large) {
      display: block;
      > li {
        display: none;
      }
    }
  }
  .flex-control-nav {
    @include media($medium-down) {
      display: block;
    }
    @include media($large) {
      display: none;
    }
  }
}
#overlay {
  .flexslider {
    .flex-direction-nav {
      display: block ;
    }
     .flex-control-nav {
      display: none ;
    }
  }
}
////////////////////////////////////////////////////////////////////

//slide effect, eliminare ogni eventuale trasformazione
.slideshow .flexslider { 
  .flex-viewport {
    height: 100%;
    .slides > li {
      overflow: hidden;
      picture {
        @include transform(none !important);
      }
    }
  }
}

// Spinner
.slideshow .flexslider {
  .spinner {
    display:none;
    width: 60px;
    height: 30px;
    text-align: center;
    font-size: 10px;
    @include position(absolute, 50% null null 50%);
    margin-top: -15px;
    margin-left: -25px;
    z-index: 10;
    > div {
      background-color: $action-color;
      height: 100%;
      width: 6px;
      display: inline-block;
      -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
      animation: stretchdelay 1.2s infinite ease-in-out;
      &.rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
      }
      &.rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
      }
      &.rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }
      &.rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
      }
    }
  }
}

@include keyframes(stretchdelay) {
  0%, 40%, 100% {@include transform(scaleY(0.4))}  
  20% {@include transform(scaleY(1))}
}








