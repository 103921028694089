body {
	color: $font-dark;
	-webkit-font-smoothing: antialiased;
	font-family: nth($body-font-family, 1);
	font-weight: nth($body-font-family, 2);
	@include set-property(font-size line-height, $body-font-size $body-line-height);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
	color: $action-color;

	font-weight: lighter;
	margin: 0;
	a {
		color: inherit !important;
		text-decoration: none !important;
		font-weight: inherit !important;
	}

	&:last-child {
		margin-bottom: 0 !important;
	}

	&.center {
		text-align: center;
	}
}

h1,
.h1 {
	font-family: nth($h1-font-family, 1);
	font-weight: nth($h1-font-family, 2);
	text-transform: nth($h2-font-family, 3);
	@include set-property(
		font-size line-height margin-bottom letter-spacing,
		$h1-font-size $h1-line-height $h1-margin-bottom $h1-letter-spacing
	);
}

h2,
.h2 {
	font-family: nth($h2-font-family, 1);
	font-weight: nth($h2-font-family, 2);
	text-transform: nth($h2-font-family, 3);
	@include set-property(
		font-size line-height margin-bottom letter-spacing,
		$h2-font-size $h2-line-height $h2-margin-bottom $h2-letter-spacing
	);
}

h3,
.h3 {
	font-family: nth($h3-font-family, 1);
	font-weight: nth($h3-font-family, 2);
	text-transform: nth($h3-font-family, 3);
	@include set-property(
		font-size line-height margin-bottom letter-spacing,
		$h3-font-size $h3-line-height $h3-margin-bottom $h3-letter-spacing
	);
}

h4,
.h4,
.tagline {
	font-family: nth($h4-font-family, 1);
	font-weight: nth($h4-font-family, 2);
	text-transform: nth($h4-font-family, 3);
	@include set-property(
		font-size line-height margin-bottom letter-spacing,
		$h4-font-size $h4-line-height $h4-margin-bottom $h4-letter-spacing
	);
}

.tagline {
	a {
		text-decoration: none;
	}
	&.back a {
		position: relative;
		z-index: 1;
		padding-left: calc(13px + 1rem);
		color: $action-color;
		&:after {
			content: "";
			background-image: url(/images/icons/icon-left-arrow-small.svg);
			background-repeat: no-repeat;
			background-position: center left;
			@include position(absolute, 50% null null 0);
			z-index: 1;
			margin-top: -5.5px;
			@include size(13px 11px);
			filter: map-get($filters, accent);
			@include transition(left $base-duration);
		}

		&:hover:after {
			left: 4px;
		}
	}
}

.small {
	font-family: nth($p-small-font-family, 1);
	font-weight: nth($p-small-font-family, 2);
	text-transform: nth($p-small-font-family, 3);
	@include set-property(
		font-size line-height margin-bottom letter-spacing,
		$p-small-font-size $p-small-line-height $p-small-margin-bottom $p-small-letter-spacing
	);
}

p strong {
	font-family: map-get($font-family, family2);
}

// Style
.link-button {
	font-family: nth($nav-font-family, 1);
	font-weight: nth($nav-font-family, 2);
	text-transform: nth($nav-font-family, 3);
	@include set-property(font-size line-height letter-spacing, $nav-font-size $nav-line-height $nav-letter-spacing);
	text-decoration: none;
}

p.link {
	a {
		@extend .link-button;
		color: black;

		display: inline-block;
		position: relative;
		z-index: 1;
		&:after {
			content: "";
			background-color: black;
			@include position(absolute, null null -2px 0);
			height: 1px;
			width: 0;
			@include transition(width $base-duration);
		}

		&:hover:after {
			width: 100%;
		}
	}
}

.button,
input[type="submit"] {
	font-family: nth($button-font-family, 1);
	font-weight: nth($button-font-family, 2);
	text-transform: nth($button-font-family, 3);
	@include set-property(font-size letter-spacing, $button-font-size $button-letter-spacing);
}

.main-nav > li {
	display: inline-block;
	margin: 0 1em;
	a {
		@extend .h2;
		display: inline-block;
	}
	&.selected a {
		text-decoration: underline;
	}
}

.top-nav-link {
	font-family: map-get($font-family, family2);
	font-size: 12px;
	letter-spacing: 1.5px;
	text-transform: uppercase;

	text-decoration: none;
}

// Rules
P + H2,
ul + H2 {
	margin-top: 1.5em;
}
P + H3,
ul + H3 {
	margin-top: 1.8em;
}
P + H4,
ul + H4 {
	margin-top: 2.4em;
}

H1 + H2 {
	margin-top: -0.5em;
}
H1 + H3 {
	margin-top: -0.6em;
}
H1 + H4 {
	margin-top: -0.8em;
}

H2 + H3 {
	margin-top: -0.5em;
}
H2 + H4 {
	margin-top: -0.6em;
}

H3 + H2 {
	margin-top: -0.25em;
}

H3 + H4 {
	margin-top: -0.5em;
}
