@mixin set-property($attributes, $variables, $important: null, $negative: null) {
  @if $negative == negative {

    @include media($small) {
      @each $attribute in $attributes {
        $i: index($attributes, $attribute);
        #{$attribute}: -(nth(nth($variables, $i), 3)) $important;
      }
    }
    @include media($medium) {
      @each $attribute in $attributes {
        $i: index($attributes, $attribute);
        #{$attribute}: -(nth(nth($variables, $i), 2)) $important;
      }
    }
    @include media($large) {
      @each $attribute in $attributes {
        $i: index($attributes, $attribute);
        #{$attribute}: -(nth(nth($variables, $i), 1)) $important;
      }
    }
    
  } @else {

    @include media($small) {
      @each $attribute in $attributes {
        $i: index($attributes, $attribute);
        #{$attribute}: nth(nth($variables, $i), 3) $important;
      }
    }
    @include media($medium) {
      @each $attribute in $attributes {
        $i: index($attributes, $attribute);
        #{$attribute}: nth(nth($variables, $i), 2) $important;
      }
    }
    @include media($large) {
      @each $attribute in $attributes {
        $i: index($attributes, $attribute);
        #{$attribute}: nth(nth($variables, $i), 1) $important;
      }
    }
  }
}

@mixin reset-lists {
  list-style-type: none;
  margin: 0;
  padding: 0;
  > li {
    position: static;
    margin: 0;
    padding: 0;
    &::before {
      display: none;
    }
  }
}

@mixin reset-inputs {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: normal;
  border-radius: 0;
  height: auto;
  width: auto;
}

@mixin bg-image($url) {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url($url);
}


@mixin outer-container-margin {
  @include outer-container;
  @include box-sizing(content-box);
  * {
    @include box-sizing(border-box);
  }

  @include set-property(
    padding-left
    padding-right,
    $paddings-outer
    $paddings-outer,
    !important
  );

}

@if $visual-grid == true {
  body:before {

    @include set-property(
      padding-left
      padding-right,
      $paddings-outer
      $paddings-outer,
      !important
    );

    @include media($large) {
      width: calc(100% - #{#{nth($paddings-outer, 1)}*2});
    }
    @include media($medium) {
      width: calc(100% - #{#{nth($paddings-outer, 2)}*2});
    }
    @include media($small) {
      width: calc(100% - #{#{nth($paddings-outer, 3)}*2});
    }

  }
}

@mixin get-icon($icon, $filter) {
  filter: map-get($filters, $filter);
  $ico: map-get($icons, $icon);
  background-image: url("#{nth($ico, 1)}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @include size(nth($ico, 2) nth($ico, 3));
  @include hide-text;
}

@mixin get-bg-icon($icon, $filter, $position) {
  position: relative;
  z-index: 1;
  &:after {
    content: "";
    @include get-icon($icon, $filter);
    position: absolute;
    z-index: 1;
  }
  @if $position == center {
    &:after {
      top: 50%;
      margin-top: -(nth(map-get($icons, $icon), 3)/2);
      left: 50%;
      margin-left: -(nth(map-get($icons, $icon), 2)/2);
    }
  }
  @if $position == left-center {
    padding-left: calc(#{nth(map-get($icons, $icon), 2)} + $icons-padding);
    &:after {
      top: 50%;
      margin-top: -(nth(map-get($icons, $icon), 3)/2);
      left: 0;
    }
  }
  @if $position == right-center {
    padding-right: calc(#{nth(map-get($icons, $icon), 2)} + $icons-padding);
    &:after {
      top: 50%;
      margin-top: -(nth(map-get($icons, $icon), 3)/2);
      right: 0;
    }
  }
  @if $position == left-top {
    padding-left: calc(#{nth(map-get($icons, $icon), 2)} + $icons-padding);
    &:after {
      top: 0;
      left: 0;
    }
  }
  @if $position == right-top {
    padding-right: calc(#{nth(map-get($icons, $icon), 2)} + $icons-padding);
    &:after {
      top: 0;
      right: 0;
    }
  }
}