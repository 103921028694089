input[type="submit"], a.button, p.button > a {
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  margin: 0;
  line-height: $input-height;
  height: $input-height;
  border: 0px;
  padding: 0;
  padding: 0 2em;

  background-color: $orange;
//   https://www.wrike.com/open.htm?id=578586150
  color: white;

}

.contourNavigation,
input[type="submit"],
p.button,
a.button {
  margin-top: 3rem;
}