ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.default {
  > li {
    position: relative;
    padding: 0;
    margin: 0 0 0 2em;
    &::before {
      position: absolute;
      left: -2em;
      content: "—";
    }
  }
}

ol.default {
  list-style-type: decimal;
  > li {
    // position: relative;
    // padding: 0 0;
    margin: 0 0 0 1rem;
    // counter-increment: step-counter;
    // &::before {
    //   position: absolute;
    //   left: -1rem;
    //   content: counter(step-counter);
    // }
  }
}






