
.mapbox-canvas {
    // 1080:650=100:x
    width: 100%;
    padding-bottom: 60.18%;
    position: relative;
    z-index: 1;
    
    .mapboxgl-compact, .mapboxgl-ctrl-compass {
        display: none;
    }

    .mapbox-marker {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('/images/icons/icon-map-arlberg.png');
        @include size(70px 88px);
        cursor: pointer;
    }

}

