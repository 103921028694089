.top-nav {
    @include position(absolute, 0 0 null 0);
	z-index: 51;
	#{$template-corporate} & {
		position: relative;
	}

    > div {	
        text-align: center;
		// transition: height $base-duration;
        @include set-property(
            (height,),
            ($top-nav-height,)
        );

        #{$template-hero} & {
            a.logo,
            a.hamburger {
                filter: map-get($filters, white);
            }
            ul li a {
                color: $font-light;
            }
        }
        #{$template-corporate} & {
            // a.hamburger,
            a.logo {
                filter: map-get($filters, blue2);
            }
            ul li a {
                color: $background-dark;
            }
		}
		
		a.hamburger,
		a.logo {
			// transition: all $base-duration;
		}

        a.hamburger {
			@extend .top-nav-link;
            @include position(absolute, 0 null null null);
            z-index: 1;
            display: block;
            background-image: url(/images/icons/icon-menu.svg);
            background-repeat: no-repeat;
            background-position: center left;
            background-size: 30px auto;
            padding-left: 40px;
			text-decoration: none;

            @include set-property(
				line-height
                height,
				$top-nav-height
				$top-nav-height
            );
            
            @include media($large) {
                left: 60px
            }
            @include media($medium) {
				right: nth($paddings-outer, 2);
				@include hide-text();
				padding-left: 0;
				width: 30px;
				background-position: center;
				background-size: contain;
            }
            @include media($small) {
				right: nth($paddings-outer, 3);
				@include hide-text();
				padding-left: 0;
				width: 30px;
				background-position: center;
				background-size: contain;
            }

            &.open {
                background-image: url(/images/icons/icon-close.svg);
                background-size: 20px auto;
            }

        }
        a.logo {
			display: inline-block;
            @include set-property(
                (height,),
                ($top-nav-height,)
            );
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            @include hide-text;
            
            @include media($medium-down) {
                position: absolute;
                top: 0;
				z-index: 1;
				left: 50%;
				transform: translateX(-50%);
            }

			// background-image: url(/images/icons/icon-logo-arlberg-hospiz.svg);
			width: 250px;
			@include media($medium) {
                width: 220px;
            }
            @include media($small) {
				width: 160px;
			}

        }   
        ul {
            @include position(absolute, 50% 60px null null );
            z-index: 1;
            @include transform(translateY(-50%));
            li {
                display: inline-block;
                & + li {
                    margin-left: 1rem;
                }
                a {
					@extend .top-nav-link;
					
					&.button {
						margin-top: 0;
						color: white !important;
					}

                }
            }

            @include media($medium-down) {
                display: none;
            }

        }
    }   

    &.is_stuck, &.open {
        > div {
            // a.hamburger,
            a.logo {
				filter: map-get($filters, blue2) !important;
			}
			a.hamburger {
				filter: map-get($filters, black) !important;
			}
			ul li a {
				color: $background-dark !important; 
				&.button {
					color: white !important;
				}
            }
        }
    }

    &.is_stuck {
		background-color: white;
		@include media($medium-down) {
			width: 65px;
		}
		> div {
			
			border-bottom: $base-border;

			height: 95px;
			a.hamburger,
			a.logo {
				line-height: 95px;
				height: 95px;
			}
			@include media($medium-down) {
				height: 65px;
				a.hamburger,
				a.logo {
					line-height: 65px;
					height: 65px;
				}
			}

			a.logo {
				background-image: url(/images/icons/icon-logo-shield.svg) !important;
				background-size: auto;
			}
		}
		
		
    }

    body &.open {
		background-color: $orange;
		a.logo,
		a.hamburger {
			filter: map-get($filters, white) !important;
		}
		a.button {
			border: 1px solid white;
		}
		ul li a {
			color: white !important;
		}
    }
    
}