.box7 {
    > div {
        @include outer-container-margin;
        > div {
            @include span-columns(12);
            position: relative;
            z-index: 1;
            > div {

                @include media($small-up) {
                    @include span-columns(6 of 12);
                    > div {
                        @include span-columns(5 of 6);
                    }
                    &.last-child {
                        &:after {
                            content: "";
                            @include position(absolute, 0 null 0 50%);
                            width: 1px;
                            background-color: $base-border-color;
                            margin-left: -.5px;
                        }
                        > div {
                            @include shift(1);
                        }
                    }
                }

                @include media($small) {
                    &.last-child {
                        padding-top: nth($gutter-ver-in-vw, 3);
                        margin-top: nth($gutter-ver-in-vw, 3);
                        position: relative;
                        z-index: 1;
                        &:after {
                            content: "";
                            @include position(absolute, 0 0 null 0);
                            height: 1px;
                            background-color: rgba(#7e7e7e, .2);
                        }
                        > div {

                            .contact {
                                text-align: center;
                                img {
                                    width: 50%;
                                    margin: 0 auto;
                                    display: inline-block;
                                }
                            }
                            
                        }
                    }
                }

            }
        }
    }
}

