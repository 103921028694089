.hero-canvas {
    
    position: relative;
    z-index: 10;

    .slideshow-holder.hero {
        z-index: 10;
        @include media($large) {
            height: calc(100vh - #{nth($sub-nav-height, 1)});
        }
        @include media($medium) {
            height: calc(100vh - 66px - #{nth($footer-mobile-height, 2)});
        }
        @include media($small) {
            height: calc(100vh - 65px - #{nth($footer-mobile-height, 3)});
        }

        .slideshow .flexslider {
            //gradiente per top-nav
            &:after {
                content :'';
                @include position(absolute , 0 0 null 0);
                height: 30%;
                z-index: 7;
                @include background-image(linear-gradient(bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 100%));
			}
			
			.flex-caption > div > div {
				vertical-align: bottom !important;
				padding-bottom: 150px;
				@include media($medium) {
					padding-bottom: 100px;
				}
				@include media($small) {
					padding-bottom: 100px;
				}
			}

        }

        .flex-control-nav {
            display: none;
        }
        .flex-direction-nav {
            display: block;
        }
    }

    .scroll-down {
        @include position(absolute , null null 40px 50%);
        z-index: 20;
        @include size(22px);
        margin-left: -11px;
        cursor:pointer;
        background-image: url(/images/icons/icon-down-arrow-small.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
		filter: map-get($filters, white);
    }
}

