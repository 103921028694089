#boxes {
	#{$template-corporate} & {
        @include set-property(
            (padding-top,),
            ($top-nav-height,)
        );
    }
}

.box {

    &, &.margin-standard {
        @include set-property(
            margin-top
            margin-bottom,
            $margins-standard
            $margins-standard
        );
    }
    &.margin-reduced {
        @include set-property(
            margin-top
            margin-bottom,
            $margins-reduced
            $margins-reduced
        );
    }
    &.background, &.margin-null {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    &.background {
        //background-color: $background-medium;
        &, &.margin-standard {
            @include set-property(
                padding-top
                padding-bottom,
                $margins-standard
                $margins-standard
            );
        }
        &.margin-reduced {
            @include set-property(
                padding-top
                padding-bottom,
                $margins-reduced
                $margins-reduced
            );
        }
    }

    &.hide-in-mobile {
        @include media($small) {
            display: none;
        }
    }

}
