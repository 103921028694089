@import "../neat/neat-helpers"; // or "../neat/neat-helpers" when not in Rails

// Neat Overrides
// $column: 90px;
$gutter: em(55px);
$grid-columns: 12;
$max-width: 1480px;

$visual-grid: false;
$visual-grid-color: yellow;
$visual-grid-index: front;
$visual-grid-opacity: 0.5;


//custom
$small: new-breakpoint(max-width em(767) 12);
$medium: new-breakpoint(min-width em(768) max-width em(1023) 12);
$large: new-breakpoint(min-width em(1024) 12);

//up not include
//down included
$small-up: new-breakpoint(min-width em(768) 12);
$medium-down: new-breakpoint(max-width em(1023) 12);