.footer-mobile {

    display: none;
    @include media($medium-down) {
        display: block;
    }
    
    border-top: 1px solid $orange;
    @include position(fixed, null  0 0 0);
    height: 55px;
    overflow: hidden;
    z-index: 41;
    
    > div {
		// @include clearfix;
		display: flex;
       > a {
		   flex: 1 1 auto;
			line-height: 55px;
			@extend .top-nav-link;  
			text-align: center;
			&:first-child {
				color: $orange;
				background-color: white;
			}
			&:last-child {
				color: white;
				background-color: $orange;
			}
        }
    }
}

