.box1 {

    overflow: hidden;

    > div {
        @include outer-container-margin;
        > div {
            @include span-columns(12);
            > div {

                @include media($small-up) {
                    @include span-columns(8 of 12)
                }
                
            }
        }
    }
    &.center {
        > div > div {

            @include media($small-up) {
                @include shift(2);
            }
            
            > div {
                text-align: center;
            }
        }
    }
    &.left {
        > div > div > div {
            text-align: left;
        }
    }
   
}

