table {
  border-collapse: collapse;
  font-feature-settings: "kern", "liga", "tnum";
  margin: 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: $base-border; //1px solid shade($base-border-color, 25%);
  font-weight: bold;
  padding: 1rem;
  text-align: left;
}

td {
  border-bottom: $base-border;
  padding: 1rem;
}

tr,
td,
th {
  vertical-align: middle;
}


.table-holder {
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; 

  > table {
    table-layout: auto;
  }
  
}