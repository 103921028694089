/* latin */
@font-face {
	font-family: "Quattrocento";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Quattrocento"),
		url(https://fonts.gstatic.com/s/quattrocento/v12/OZpEg_xvsDZQL_LKIF7q4jP3w2j6XYvVuQ.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
	font-family: "Quattrocento Sans Regular";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Quattrocento Sans"), local("QuattrocentoSans"),
		url(https://fonts.gstatic.com/s/quattrocentosans/v13/va9c4lja2NVIDdIAAoMR5MfuElaRB0zJt0_8H3HI.woff2)
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
	font-family: "Quattrocento Sans Bold";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local("Quattrocento Sans Bold"), local("QuattrocentoSans-Bold"),
		url(https://fonts.gstatic.com/s/quattrocentosans/v13/va9Z4lja2NVIDdIAAoMR5MfuElaRB0RyklrRPXziirsI.woff2)
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
