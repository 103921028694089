.box3 {
    > div {
        @include outer-container-margin;
        > div {
            @include span-columns(12);
            > div {
                @extend .list-item;
            }
        }
    }
}