
//nel caso jquery ui css non fosse caricato
// .ui-helper-hidden-accessible {
// 	display: none !important;
// }

.imgmap-tooltip,
.ui-tooltip {


  position:absolute;
  text-align: center;
  z-index:100;
  
  //sovrascrive jquery ui (eliminare !important e proprietà non usate se ui.css non presente)
  border:0 !important;
  padding: 8px 10px !important;
  background-color: black !important;
  box-shadow: none !important;
  background-image: none !important;
  color: white !important;
  font-size: .8rem !important;
  max-width: none !important;

  font-family: nth($h4-font-family, 1) !important;
  font-weight: nth($h4-font-family, 2) !important;
  text-transform: nth($h4-font-family, 3) !important;
  letter-spacing: 1px !important;

  //triangolo
  width: 200px;
  &:after {
    content: "";
    position: absolute;
    display: block;
    background-color: black;
    bottom: -6px;
    left: 94px;
    @include size(12px);
    @include transform(rotate(-45deg));
  }


}