.box5 {
    > div {
        @include outer-container-margin;
        > div {
            @include span-columns(12);
            > div:first-child > div {
                text-align: center;
            }
            @include media($small-up) {
                display: flex;
                align-items: center;
                > div {
                    @include span-columns(6 of 12);
                    &:first-child {
                        > div {
                            @include span-columns(5 of 6);
                        }
                    }
                }
            }
            @include media($small) {
                > div:last-child {
                    margin-top: nth($gutter-ver-in-vw, 3);
                }
            }
        }
    }

    @include media($small-up) {
        &.inverted {
            > div {
                > div {
                    flex-direction: row-reverse;
                    > div {
                        &:first-child {
                            margin-right: 0;
                            margin-left: flex-gutter();
                            > div {
                                @include shift(1);
                            }
                        }
                    }
                }
            }
        }
    }
}