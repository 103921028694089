.main-nav {
    display: none;
    @include position(fixed, 0);
    z-index: 50;
    background-color: $orange;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;    

    > div {

        @include set-property(
            (margin-top,),
            ($top-nav-height,)
        );

        @include outer-container-margin;
        @include set-property(
            padding-top
            padding-bottom,
            $margins-reduced
            $margins-reduced
        );

        * {
            color: $font-light !important;
        }


        > div { 
            @include span-columns(12);
            margin-top: 37px;

            > div {

                @include media($small-up) {
                    &:first-child {
                        @include span-columns(7 of 12);
                    }
                    &:last-child {
                        @include span-columns(5 of 12);
                    }
                }

                &:first-child {
                    //main
                    ul.menu {
                        li {
                            a {
                                @extend .h3;
                                text-decoration: none;
                                display: block;
                                line-height: 1.5em;
                                @include transition(opacity $base-duration);
							}
							& + li {
								margin-top: 1rem;
							}
                        }

                        // @include media($medium-down) {
                        //     position: relative;
                        //     z-index: 1;
                        //     padding-bottom: 37px;
                        //     margin-bottom: 37px;
                        //     &:after {
                        //         @include position(absolute, null null 0 0);
                        //         z-index: 1;
                        //         content: "";
                        //         height: 1px;
                        //         width: 50px;
                        //         background-color: rgba(white, .25);
                        //     }
						// }
						
						 @include media($small) {
                            position: relative;
                            z-index: 1;
                            padding-bottom: 37px;
                            margin-bottom: 37px;
                            &:after {
                                @include position(absolute, null null 0 0);
                                z-index: 1;
                                content: "";
                                height: 1px;
                                width: 50px;
                                background-color: rgba(white, .25);
                            }
						}

                    }
                }
                &:last-child {

					> ul {
						position: relative;
						z-index: 1;
						padding-bottom: 37px;
						margin-bottom: 37px;
						&:after {
							@include position(absolute, null null 0 0);
							z-index: 1;
							content: "";
							height: 1px;
							width: 50px;
							background-color: rgba(white, .25);
						}
						li {
							display: inline-block;
							a {
								@extend .top-nav-link;
								opacity: .35;
							}
							&.selected a {
								opacity: 1;
							}
							& + li {
								margin-left: 1rem;
							}
						}
					}

                    .corporate-nav {
						position: relative;
						padding-top: 37px;
						margin-top: 37px;
						&:after {
							@include position(absolute, 0 null null 0);
							z-index: 1;
							content: "";
							height: 1px;
							width: 50px;
							background-color: rgba(white, .25);
						}
                        ul li {
                            a {
                                @extend .top-nav-link;
                            }
                            & + li {
                                margin-top: 1rem;
                            }
                        }
                        @include media($medium-down) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}