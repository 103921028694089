.box6 {
    .item {
        @extend .box5;
        
        @include media($small) {
            > div > div {
                display: flex;
                flex-direction: column-reverse;
                > div:last-child {
                    margin-top: 0;
                    margin-bottom: nth($gutter-ver-in-vw, 3);
                    @include span-columns(8 of 12);
                    @include shift(2);
                }
            }
        }

    }

    &.inverted {
        .item {
            @extend .box5.inverted;
        }
    }

    > div:first-child {

        @include media($medium-down) {

            .slider-nav {
                position: relative;
                @include set-property(
                    margin-left
                    margin-right,
                    $paddings-outer
                    $paddings-outer,
                    null,
                    negative
                );
                &:after, &:before {
                    content: "";
                    @include position(absolute , 0 null 0 null);
                    z-index: 1;
                    width: nth($paddings-outer, 3);
                    background-color: rgba(white, .8);
                }
                &:before {
                    left: 0;
                }
                &:after {
                    right: 0
                }
                ul {
                    white-space: nowrap;
                    overflow: hidden;
                    overflow-x: auto;
                    -webkit-overflow-scrolling: touch;    
                    
                    li {
                        &:first-child {
                            @include set-property(
                                (padding-left,),
                                ($paddings-outer,)
                            );
                        }
                        &:last-child {
                            @include set-property(
                                (padding-right,),
                                ($paddings-outer,)
                            );
                        }
                    }
                }
                


            }
        }

        @include outer-container-margin;
        @include set-property(
            (margin-bottom,),
            ($margins-standard,)
        );
        
        > div {
            @include span-columns(12);
            text-align: center;
            ul {
                li {
                    display: inline-block;
                    & + li {
                        margin-left: 2rem;
                    }
                    a {
                        @extend .h4;
                        text-decoration: none;
                        color: $base-font-color;
                    }
                    &.selected a {
                        color: $action-color;
                    }
                }
            }
        }
    }

    .flex-direction-nav, .flex-control-nav {
        display: none;
    }


    &.background > div:first-child .slider-nav {
        &:after, &:before {
            background-color: rgba($background-medium, .8);
        }
    }

}