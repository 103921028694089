//#boxes > .box:not(:first-child) {
#boxes > .box {
    opacity: 0;
    &.background {
        @include transition(background-color .5s $base-timing);
        @include transition-delay(1s);
    }
    &.fadeInUp {
        @include animation(fadeInUp 1s $base-timing forwards);
        &.background {
            background-color: $background-medium;
        }
    }
}

@include keyframes(fadeInUp) {
    from {
      opacity: 0;
      @include transform(translate3d(0, 20%, 0));
    }
    to {
      opacity: 1;
      @include transform(none);
    }
}