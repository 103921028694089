.box9 {
    > div {
        @include outer-container-margin;
        > div {
            text-align: center;
            &.first-child {

                @include media($small-up) {
                    @include span-columns(8);
                    @include shift(2);
                }
            }
            &.last-child {
                @include span-columns(12);
                &:not(:first-child) {
                    @include set-property(
                        (margin-top,),
                        ($margins-reduced,)
                    );
                }

                .slides > li {
                    > div {

                        > div {
                            &:first-child {
                                a {
                                    display: block;
                                }
                                img {
                                    display: block;
                                    width: 100%;
        
                                    @include media($large) {
                                        margin-bottom: 2em;
                                    }
                                }

                                
                            }

                            @include media($small) {
                                &:first-child {
                                    img {
                                        margin-bottom: 60px;
                                    }
                                }
                                &:last-child {
                                    p {
                                        display: none;
                                    }
                                    h3 {
                                        margin-bottom: 0;
                                    }
                                }
                            }

                        }

                        @include media($medium) {
                            @include span-columns(12 of 12);
                            display: flex;
                            flex-direction: row-reverse;
                            align-items: center;
                            > div {
                                @include span-columns(6 of 12);
                                &:first-child {
                                    margin-right: 0;
                                    margin-left: flex-gutter();
                                }
                                &:last-child {
                                    > div {
                                        @include span-columns(5 of 6);
                                    }
                                }
                            }
                        }
                        
                    }
                }
                
                
                //previsti solo 3 elementi
                .flex-direction-nav, .flex-control-nav li:nth-child(n+4) {
                    display: none;
                }
                
            }
        }
       
    }
    
}

