.sub-nav {
    background-color: #f8f8f8;
	z-index: 40;
	position: relative;

    ul {
        padding: 1.5em 0;
        text-align: center;
        li {
            display: inline-block;
            & + li {
                margin-left: 1rem;
            }
            a {
                @extend .top-nav-link;
            }
            &.selected a {
				border-bottom: 1px solid $base-font-color;
            }
        }
    }

    @include media($large) {
        > div {
            @include outer-container-margin;
        }
    }
    
    @include media($medium-down) {
        // position: relative;
        // &:after, &:before {
        //     content: "";
        //     @include position(absolute , 0 null 0 null);
        //     z-index: 1;
        //     width: nth($paddings-outer, 3);
        //     background-color: rgba(#f8f8f8, .8);
        // }
        // &:before {
        //     left: 0;
        // }
        // &:after {
        //     right: 0
        // }
        ul {
            white-space: nowrap;
            overflow: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;    
            li {
                &:first-child {
                    @include set-property(
                        (margin-left,),
                        ($paddings-outer,)
                    );
                }
                &:last-child {
                    @include set-property(
                        (margin-right,),
                        ($paddings-outer,)
                    );
                }
            }
        }
    }

    #{$template-corporate} & {

        & + #boxes {
            @include set-property(
                (margin-top,),
                ($top-nav-height,),
                null,
                negative
            );
        }
    }

    
}

