// Colors
$orange: #25435D;
$font-dark: #333;
$font-light: #fff;
$background-medium: #d7d2cb;
$background-dark: #000;
$blue: #25435d;
$gold: #9a8f7c;

// Borders
$base-border-color: rgba(#7e7e7e, 0.2); //$background-medium;
$base-border: 1px solid $base-border-color;

// Font Colors
$base-font-color: $font-dark;
$action-color: $orange;

// Forms and Buttons
$input-height: 52px;

// Filters
$filters: (
	dark: invert(18%) sepia(0%) saturate(1221%) hue-rotate(147deg) brightness(95%) contrast(92%),
	accent: invert(18%) sepia(72%) saturate(433%) hue-rotate(166deg) brightness(102%) contrast(88%), //invert(8%) sepia(97%) saturate(7199%) hue-rotate(338deg) brightness(74%) contrast(99%),
	white: invert(100%) sepia(64%) saturate(2%) hue-rotate(217deg) brightness(105%) contrast(100%),
	black: invert(0%) sepia(99%) saturate(0%) hue-rotate(301deg) brightness(95%) contrast(103%),
	blue: invert(18%) sepia(72%) saturate(433%) hue-rotate(166deg) brightness(102%) contrast(88%),
	gold: invert(68%) sepia(15%) saturate(418%) hue-rotate(359deg) brightness(82%) contrast(81%),
	blue2: invert(24%) sepia(15%) saturate(1894%) hue-rotate(166deg) brightness(85%) contrast(87%)
);

// Font families
$font-family: (
	family1: "Quattrocento Sans Regular",
	family2: "Quattrocento Sans Regular",
	family3: "Quattrocento Sans Bold",
);

// Templates
$template-corporate: 'body[class*="Corporate"]';
$template-hero: 'body[class*="Hero"]';

// arrays (breakpoints: large, medium, small)
$paddings-outer: (12.5vw, 8vw, 40px);
$margins-standard: (144px, 110px, 72px); //se cambia cambiare JS
$margins-reduced: (101px, 72px, 54px);
$gutter-hor-in-vw: (1.5vw, 1.5vw, 3vw);
$gutter-ver-in-vw: (0, 0, 40px);
$top-nav-height: (135px, 105px, 85px);
$sub-nav-height: (85px, 66px, 66px);
$logo-width: (42.5px, 32.5px, 27.5px);
$footer-mobile-height: (null, 55px, 55px);

// Animations
$base-duration: 250ms;
$base-timing: $ease-in-out-cubic;
$linear-timing: cubic-bezier(0.25, 0.25, 0.75, 0.75);
$opacity: 0.8;

// H1
$h1-font-family: (map-get($font-family, family1), normal, none);
$h1-font-size: (3.375em, 3em, 2.29em);
$h1-line-height: (1.12em, 1.12em, 1.12em);
$h1-margin-bottom: (0.5em, 0.5em, 0.5em);
$h1-letter-spacing: (0px, 0px, 0px);

// H2
$h2-font-family: (map-get($font-family, family1), normal, none);
$h2-font-size: (2.375em, 1.71em, 1.71em);
$h2-line-height: (1.24em, 1.24em, 1.24em);
$h2-margin-bottom: (0.5em, 0.5em, 0.5em);
$h2-letter-spacing: (0px, 0px, 0px);

// H3
$h3-font-family: (map-get($font-family, family1), normal, none);
$h3-font-size: (1.26em, 1.36em, 1.36em);
$h3-line-height: (1.2em, 1.2em, 1.2em);
$h3-margin-bottom: (0.7em, 0.7em, 0.7em);
$h3-letter-spacing: (0px, 0px, 0px);

// H4
$h4-font-family: (map-get($font-family, family2), normal, none);
$h4-font-size: (0.78em, 1em, 1em);
$h4-line-height: (1.3em, 1.3em, 1.3em);
$h4-margin-bottom: (0.8em, 0.8em, 0.8em);
$h4-letter-spacing: (0px, 0px, 0px);

// Body, P
$body-font-family: (map-get($font-family, family2), normal, none);
$body-font-size: (16px, 15px, 15px);
$body-line-height: (1.6em, 1.6em, 1.6em);

// P.small
$p-small-font-family: (map-get($font-family, family1), normal, uppercase);
$p-small-font-size: (0.67em, 0.78em, 0.78em);
$p-small-line-height: (1.6em, 1.6em, 1.6em);
$p-small-margin-bottom: (0.8em, 0.8em, 0.8em);
$p-small-letter-spacing: (1px, 1px, 1px);

// P.big
$p-big-font-family: (map-get($font-family, family1), normal, uppercase);
$p-big-font-size: (1.125em, 1.067em, 1.067em);
$p-big-line-height: (1.5em, 1.5em, 1.5em);
$p-big-margin-bottom: (0.8em, 0.8em, 0.8em);
$p-big-letter-spacing: (0px, 0px, 0px);

// Main navigation links
$nav-font-family: (map-get($font-family, family3), normal, uppercase);
$nav-font-size: (0.8em, 0.8em, 0.8em);
$nav-line-height: (1.5em, 1.5em, 1.5em);
$nav-letter-spacing: (2px, 2px, 2px);

// Buttons
$button-font-family: (map-get($font-family, family2), normal, uppercase);
$button-font-size: (0.8em, 0.8em, 0.8em);
$button-letter-spacing: (2px, 2px, 2px);
