.footer {
    border-top: $base-border;

    @include set-property(
        padding-top
        padding-bottom,
        $margins-standard
        $margins-standard
    );

    //footer-mobile h
    @include media($medium-down) {
        margin-bottom: 55px;
    }

    > div {
        @include outer-container-margin;
        > div {
			@include span-columns(12);

			@include media($medium-down) {
				display: flex;
				flex-direction: column-reverse;
			}

            > div {

                @include media($large) {
                    &:first-child {
                        @include span-columns(5 of 12);
                    }
                    &:last-child {
                        @include span-columns(7 of 12);
                        text-align: right;
                    }
                }

                @include media($medium-down) {
                    &:last-child {
                        margin-bottom: 3rem;
                    }
                }

				&:first-child {
					ul.social {
                        li {
                            display: inline-block;
                            vertical-align: middle;
                            & + li {
                                margin-left: 1.5em;
                            }
                            a {
								display: block;
								img {
									filter: map-get($filters, gold);
								}
                            }
                        }
                    }
				}
                &:last-child {
                    ul {
                        li {
                            display: inline-block;
                            & + li {
                                margin-left: 1.5em;
                            }
                            a {
								text-decoration: none;
							}

							@include media($small) {
								display: block;
								margin-left: 0 !important;
							}

                        }
                    }
                }
            }
        }
    }
}

