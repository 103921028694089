fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
textarea,
select {
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  margin: 0;
  line-height: $input-height;
  height: $input-height;
  width: 100%;
  @include prefixer(appearance, none, webkit moz o spec);
  border: 0;
  box-sizing: border-box;
  background-color: $background-medium;
  padding: 0 1rem;
  &:focus {
    outline: none;
  }
}

textarea {
  resize: vertical;
  height: 6rem;
  line-height: auto;
}

select {
  background-image: url(/images/icons/icon-dropdown.svg);
  background-position: center right 1rem;
  padding-right: calc(2rem + 12px);
  background-repeat: no-repeat;
  background-size: 12px auto;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 10px;
  line-height: auto;
}

// Contour
.contour {
  fieldset {
    margin-bottom: 0;
  }

  &.no-label {
    .umbraco-forms-field:not(.checkbox):not(.multiplechoice) {
      > label:not([for="checkboxes"]):not([for="radio_buttons"]) {
        display: none;
      }
    }
  }

  &.no-placeholder {
    input[type="text"],
    textarea {
      &::-webkit-input-placeholder {
        color: transparent !important;
      }
      &::-moz-placeholder {
        color: transparent !important;
      }
      &:-ms-input-placeholder {
        color: transparent !important;
      }
      &:-moz-placeholder {
        color: transparent !important;
      }
    }
  }

  .umbraco-forms-field {
    label {
      @extend .small;
      display: block;
    }

    &.checkbox {
      position: relative;
      > label {
        padding-left: 30px;
      }
      > div {
        position: static;
        input {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    &.multiplechoice {
      > div {
        position: relative;
        > span {
          display: block;
          br {
            display: none;
          }
          > label {
            padding-left: 30px;
            display: block;
          }
          input {
            position: absolute;
          }
        }
      }
    }

    > div {
      .checkboxlist,
      .radiobuttonlist {
        > label {
          font-family: inherit;
          text-transform: none;
          letter-spacing: 0;
          font-size: 1rem;
          color: inherit;
          margin-bottom: 0 !important;
          border: 0;
          display: inline-block;
          width: auto;
        }
      }

      .field-validation-error {
        display: block;
        color: red;
        padding: 0.5rem 1rem;
        background-color: lighten(red, 40);
        position: relative;
        &:before {
          content: "⚠ ";
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

    .contourNavigation {
        text-align: right;
        a {
            @extend .link-button;
            color: $action-color;
            display: inline-block;
            position: relative;
            z-index: 1;
            padding-right: calc(13px + 1rem);
            &:after {
              content :"";
              @include size(13px 11px);
              background-image: url(/images/icons/icon-right-arrow-small.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              filter: map-get($filters, accent);
              @include position(absolute, 50% 0 null null);
              @include transform(translateY(-50%));
              @include transition(right $base-duration);
            }

            &:hover:after {
              right: 4px;
            }

        }
    }
}
